<template>
  <div class="home-page">
    <div :class="'page first-page ' + pageW" >
      <div class="home-content">
        <div class="left-part" :style="'zoom:'+zoom">
          <img :src="require('./assets/img/cmdp.png')">
          <div class="en">Capital Market Digital Platform</div>
          <div class="brief"><span>利用数字化技术，汇集并连接资本市场知识，为每</span><span>个资本人提供数字智库，同时也为专业知识开创新</span>的价值实现模式</div>
          <div class="zn">
            <div class="links">
              <div class="part_left"  @click="goVista" @mouseover="circleBgVisible=false;circleBgLeftVisible=true" @mouseleave="circleBgVisible=true;circleBgLeftVisible=false"></div>
              <div class="part_right"  @click="goStd" @mouseover="circleBgVisible=false;circleBgRightVisible=true" @mouseleave="circleBgVisible=true;circleBgRightVisible=false"></div>
              <div class="bg" v-show="circleBgVisible"></div>
              <div class="bg-left" v-show="circleBgLeftVisible"></div>
              <div class="bg-right" v-show="circleBgRightVisible"></div>
            </div>
          </div>
          <div class="zn-txt">
            <p>为每个<span class="font-bold">资本人<span class="font-blue">提供</span>随身智库</span></p>
            <p>为每一份<span class="font-bold">智慧<span class="font-blue">构建</span>数字价值</span></p>
          </div>
        </div>

        <div class="right-float" :style="'zoom:'+zoom">
          <div class="icon icon1"><img :src="require('./assets/img/qrcode.png')"></div>
          <div class="icon icon2"></div>
          <div class="icon icon3"></div>
        </div>
      </div>
<!--      <img class="bg-img" :src="firstBgImgSrc">-->
    </div>
    <div :class="'page second-page ' + pageW">
      <img class="bg-img" :src="secondBgImgSrc">
      <div class="aimtarget ycl" ref="wYcl" @mouseover="showSecondBgTxt('ycl')" @mouseout="hideSecondBgTxt"></div>
      <div class="aimtarget sjt" ref="wSjt" @mouseover="showSecondBgTxt('sjt')" @mouseout="hideSecondBgTxt"></div>
      <div class="aimtarget yhsj" ref="wYhsj" @mouseover="showSecondBgTxt('yhsj')" @mouseout="hideSecondBgTxt"></div>
      <div class="aimtarget xdd" ref="wXdd" @mouseover="showSecondBgTxt('xdd')" @mouseout="hideSecondBgTxt"></div>show
      <div class="aimtarget gz" ref="wGz" @mouseover="showSecondBgTxt('gz')" @mouseout="hideSecondBgTxt"></div>
      <div class="aimtarget qzdcc" ref="wQzdcc" @mouseover="showSecondBgTxt('qzdcc')" @mouseout="hideSecondBgTxt"></div>
      <div class="txt aimtarget-txt" ref="wTxt" v-show="secondBgTxtVisible"><span>{{ secondBgTxt }}</span></div>
    </div>
    <div class="page thread-page">
<!--      <img class="bg-img" :src="threadBgImgSrc">-->
    </div>
    <webFooter></webFooter>

    <el-dialog :visible.sync="dialogLoginVisible" width="410px">
      <div class="pri-text"><h3 class="impt">微信登录</h3></div>
      <div>
        <wechat-login v-if="dialogLoginVisible" @login-success="onLoginSuccess"></wechat-login>
      </div>
      <div style="text-align: center">请使用微信扫描二维码登录</div>
    </el-dialog>
    <role-selector
        :dialog-role-visible="dialogRoleVisible"
        :match-tags="matchTags"
        @tagChanged="tagChanged"
        @close="dialogRoleVisible=false"
    ></role-selector>
  </div>
</template>

<script>
import userApi from "../../assets/js/userApi";
import CryptoJS from 'crypto-js/index';
import wechatLogin from "./components/wechat-login"
import webFooter from "./components/webFooter"
import RoleSelector from "./components/roleSelector";

export default {
  name: "",
  components: {RoleSelector, wechatLogin, webFooter},
  data() {
    return {
      appAct: "",
      showLogin: false,
      showFollow: false,
      showErrInfo: false,
      wxClientLogin: false,
      viewMenu: false,
      dialogLoginVisible: false,
      dialogRoleVisible: false,
      circleBgVisible: true,
      circleBgLeftVisible: false,
      circleBgRightVisible: false,
      secondBgTxtVisible: false,
      secondBgTxt: '',
      screenWidth:null,
      screenHeight:null,
      pageW: 'w1920',
      firstBgImgSrc: './assets/img/platform_1920.png',
      secondBgImgSrc: './assets/img/index_second_bg.jpg',
      threadBgImgSrc: './assets/img/index_thread_bg.jpg',
      zoom: 1,
      one: true,
      matchTags      : ['role_券商','role_评级机构','role_上市公司','role_中小企业','role_代理记账'],  //当前页面管理的标签
      secondBgTxts: {
        xdd: '输入excel和word文档，在CMDP专业版工厂中，联合小智速联的数据、技术及规则，一站式产出工具、系统。让自己的知识为他人创造价值。',
        sjt: 'CMDP标准版工厂将利用小智速联自有数据、技术及规则，为商机的挖掘、标的价值的判断、各级风险的预警以及在作业过程中重点难点的操作，如材料填报、智能审核，系统操控、报表处理、票据分析等，提供智库服务。资本人根据需要一键下达订单，享受服务。',
        ycl: '23年始陪伴监管的经验，资本市场一手全量数据；监管视角，在标准数据之外，拥有另类数据、衍生数据等，全方位侧写标的行为。根据客户设计图或订单进行分析，自动识别并提取出所需的股债基数据、规则、模型等，为自动化产出智库做准备。',
        yhsj: '配合用户本地数据，智能抓取、识别整理，个性化定制出锦囊妙计',
        gz: '1999年至今，23年的监管业务累计，始自监管的业务规则，佐以市场及专业大咖的规则，对业务、规则标准化，为智囊提供多视角多维度业务规则支撑',
        qzdcc: '通过对底层技术、规则和数据的标准化封装，基于业务场景的路径自动发现，构建出个性化系统，为商机的挖掘、标的价值的判断、各级风险的预警以及在作业过程中重点难点的专业操作提供辅助。为每个资本人联接数字智库，为每个知识联接价值实现'
      }
    }
  },
  watch: {
    '$route'(to, from) {
      // 路由发生变化页面刷新
      this.$router.go(0);
    },
    screenHeight: {
      handler: function (val, oldVal) {
        console.log( 'screenHeight=' + val );
      }
    },
    screenWidth: {
      handler: function (val, oldVal) {
        if (val >= 1600 ) {
          this.zoom = 1
          document.documentElement.style.setProperty("--zoom", this.zoom);
        } else {
          this.zoom = Math.floor(val/1600*100)/100
          this.zoom = this.zoom < 0.6 ? 0.6 : this.zoom
          document.documentElement.style.setProperty("--zoom", this.zoom);
        }
        console.log( 'screenWidth=' + val + ' zoom=' + this.zoom);
        let pageW = val >= 1920 ? 1920 : val
        $( this.$refs.wYcl ).css( {
          width : `${pageW / 1920  * 86}px`,
          height : `${pageW / 1920  * 86}px`,
        } );
        $( this.$refs.wSjt ).css( {
          width : `${pageW / 1920  * 86}px`,
          height : `${pageW / 1920  * 86}px`,
        } );
        $( this.$refs.wYhsj ).css( {
          width : `${pageW / 1920  * 86}px`,
          height : `${pageW / 1920  * 86}px`,
        } );
        $( this.$refs.wXdd ).css( {
          width : `${pageW / 1920  * 86}px`,
          height : `${pageW / 1920  * 86}px`,
        } );
        $( this.$refs.wGz ).css( {
          width : `${pageW / 1920  * 86}px`,
          height : `${pageW / 1920  * 86}px`,
        } );
        $( this.$refs.wQzdcc ).css( {
          width : `${pageW / 1920  * 86}px`,
          height : `${pageW / 1920  * 86}px`,
        } );
        $( this.$refs.wTxt ).css( {
          width : `${pageW / 1920  * 570}px`,
          height : `${pageW / 1920  * 348}px`,
        } );

        if (val <= 750) {
          this.firstBgImgSrc = require('./assets/img/platform_750.png')
          this.secondBgImgSrc = require('./assets/img/index_second_bg_750.jpg')
          this.threadBgImgSrc = require('./assets/img/index_thread_bg_750.jpg')
          this.pageW = 'w750'

          $( this.$refs.wYcl ).css( {
            width : `${val / 750  * 44}px`,
            height : `${val / 750  * 44}px`,
          } );
          $( this.$refs.wSjt ).css( {
            width : `${val / 750  * 44}px`,
            height : `${val / 750  * 44}px`,
          } );
          $( this.$refs.wYhsj ).css( {
            width : `${val / 750  * 44}px`,
            height : `${val / 750  * 44}px`,
          } );
          $( this.$refs.wXdd ).css( {
            width : `${val / 750  * 44}px`,
            height : `${val / 750  * 44}px`,
          } );
          $( this.$refs.wGz ).css( {
            width : `${val / 750  * 44}px`,
            height : `${val / 750  * 44}px`,
          } );
          $( this.$refs.wQzdcc ).css( {
            width : `${val / 750  * 44}px`,
            height : `${val / 750  * 44}px`,
          } );
          $( this.$refs.wTxt ).css( {
            width : `${pageW / 1920  * 570}px`,
            height : `${pageW / 1920  * 348}px`,
          } );

        } else if (val > 750 && val <= 1200 ) {
          this.firstBgImgSrc = require('./assets/img/platform_1920.png')
          this.secondBgImgSrc = require('./assets/img/index_second_bg.jpg')
          this.threadBgImgSrc = require('./assets/img/index_thread_bg.jpg')
          this.pageW = 'w1200'
          //此刻屏幕宽度大于800
        } else {
          this.firstBgImgSrc = require('./assets/img/platform_1920.png')
          this.secondBgImgSrc = require('./assets/img/index_second_bg.jpg')
          this.threadBgImgSrc = require('./assets/img/index_thread_bg.jpg')
          this.pageW = 'w1920'
        }

      },
    },
  },
  created() {
    this.$EventBus.$on("viewMenu", viewMenu => this.viewMenu = viewMenu);
    this.init();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })()
    }
  },
  beforeMount() {
    this.$nextTick( _=> {
      this.screenWidth = document.body.clientWidth  - 18;
      this.screenHeight = document.body.clientHeight;
    } )
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      sessionStorage.setItem("USER_SYSTEM", this.$route.query?.s || 'web');
      this.appAct = config.appAct;
      let _errCode = this.$route.query?.errcode;
      /*系统错误处理*/
      if (_errCode) {
        this.showErrInfo = true;
        return;
      }

      if (this.$route.query?.tp == "wx") {
        this.wxClientLogin = true;
      }

      /**
       * 处理微信自动登录
       */
      if (this.$route.query?.code) {
        this.wxClientLogin = true;
        let _code = this.$route.query?.code;
        let _p = this.$route.query?.p;
        let _subscribe = this.$route.query?.subscribe == "1" ? true : false;

        /*未关注用户处理*/
        if (!_subscribe) {
          this.showFollow = true;
          return;
        }

        /*用户登录*/
        try {
          /*获取登录信息*/
          let _loginInfo = JSON.parse(CryptoJS.enc.Base64.parse(_code).toString(CryptoJS.enc.Utf8));
          userApi.doLogin(_loginInfo).then(_data => {
            this.onLoginSuccess();
          }).catch(_r => {
            this.$notify({type: 'warning', message: '登录失败'});
            this.showLogin = true;
          })
        } catch (e) {
          console.error('wx-login faild')
        }
      } else {
        this.showLogin = true;
      }
    },

    tagChanged(){
      this.dialogRoleVisible = false;
      this.$nextTick( _=> {
        window.setTimeout( _=> window.open("/std" ), 100 );
      } );
    },

    /**
     * 获取当前用户信息
     */
    getCurUserInfo() {
      return userApi.getCurUsrInfo();
    },

    onLoginSuccess() {
      this.dialogLoginVisible = false;
      sessionStorage.setItem( 'USER_ISLOGIN', true );
      let _curUserInfo        = JSON.parse( sessionStorage.getItem( "USER_INFO" ) );
      let _tags               = _curUserInfo?.tags;
      if( _tags ){
        let _matchTag = Object.keys( _tags ).find( _tag => this.matchTags.indexOf( _tag ) >= 0 );
        if( _matchTag ) {
          /*let a = document.createElement('a');
          a.rel = 'opener'
          a.target = '_blank'
          a.href = "/std"
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);*/
          //  window.open('/std')
          let url = "/std"
        //  window.open('javascript:window.name;', '\u003cscript\u003elocation.reolace("'+url+'")\u003c\/script\u003e')
        //  window.open('javascript:window.name;', '<script>location.replace(" '+url+' ")<\/script>')
        //  window.open('/std')
          this.dialogRoleVisible = true;
        }
        else
          this.dialogRoleVisible = true;
      }else{
        this.dialogRoleVisible = true;
      }
    },

    goWheel() {
      if (event.deltaY > 0 && this.one==true) { //data中定义one为true 当one为true时执行
        this.$refs.carousel.next();           //以此来控制每次轮播图切换的张数
        this.one=false;
        setTimeout(()=>{
          this.one=true
        },500)
      }

      if (event.deltaY < 0 && this.one==true) {
        this.$refs.carousel.prev();
        this.one=false;
        setTimeout(()=>{
          this.one=true
        },500)
      }
    },
    showSecondBgTxt(val) {
      this.secondBgTxt = this.secondBgTxts[val]
      this.secondBgTxtVisible = true
    },
    hideSecondBgTxt() {
      this.secondBgTxtVisible = false
    },
    goVista() {
      // window.open('/vista.html')
      window.open('/df');//前往数字工厂项目
    },

    goStd() {
      window.open('/std/cmdp/marketcmdp')
    }
  }
}
</script>
<style lang="less" scoped>
@import "./assets/index.less";
</style>
<style lang="less">
body{
  overflow:auto;
}
:root{
  --zoom: 1;
}
.el-popover.poper-tragger {
  background: rgba(255, 255, 255, .8) !important;
  border-radius: 10px;
}

.el-popover.poper-tragger .popper__arrow {
  display: none;
}

.el-popover.poper-links {
  min-width: 50px;
  zoom: var(--zoom) !important;
}
.aimtarget-txt span{
  zoom: var(--zoom) !important;
}
.home-content .left-part .zn{
  width: calc(var(--zoom)*468px) !important;
  height: calc(var(--zoom)*468px) !important;
  transform: scale(var(--zoom));
}
</style>
