<template>
  <el-dialog
      v-loading="loading"
      element-loading-background="rgba(255, 255, 255, 0.5)"
      top="0vh"
      :title="title"
      :visible.sync="dialogRoleVisible"
      append-to-body
      custom-class="dialog-role"
      @close="onClosed"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
    <div class="choice-container">
      <div class="touhang"  @click.stop="choiceRoleTag('投行')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '投行' && showRole">
          <div class="role role_1" @click.stop="choiceRole('承揽')"></div>
          <div class="role role_2" @click.stop="choiceRole('承做')"></div>
          <div class="role role_3" @click.stop="choiceRole('内核')"></div>
          <div class="role role_4" @click.stop="choiceRole('合规')"></div>
          <div class="role role_5" @click.stop="choiceRole('承销')"></div>
          <div class="role role_6" @click.stop="choiceRole('高管')"></div>
        </div>
      </div>
      <div class="pingjijigou" @click.stop="choiceRoleTag('评级机构')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '评级机构' && showRole">
          <div class="role role_1" @click.stop="choiceRole('分析师')"></div>
          <div class="role role_2" @click.stop="choiceRole('内核')" ></div>
          <div class="role role_3" @click.stop="choiceRole('高管')"></div>
        </div>
      </div>
      <div class="shangshigs" @click.stop="choiceRoleTag('上市公司')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '上市公司' && showRole">
          <div class="role role_1" @click.stop="choiceRole('董事长')"></div>
          <div class="role role_2" @click.stop="choiceRole('董秘')" ></div>
          <div class="role role_3" @click.stop="choiceRole('证代')"></div>
          <div class="role role_4" @click.stop="choiceRole('财务总监')"></div>
        </div>
      </div>
      <div class="zhongdaaoqy" @click.stop="choiceRoleTag('中大企业')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '中大企业' && showRole">
          <div class="role role_1" @click.stop="choiceRole('董事长')"></div>
          <div class="role role_2" @click.stop="choiceRole('总经理')" ></div>
          <div class="role role_3" @click.stop="choiceRole('财务总监')"></div>
        </div>
      </div>
      <div class="caishui" @click.stop="choiceRoleTag('财税服务')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '财税服务' && showRole">
          <div class="role role_1" @click.stop="choiceRole('代记')"></div>
          <div class="role role_2" @click.stop="choiceRole('审计')" ></div>
          <div class="role role_3" @click.stop="choiceRole('税筹')"></div>
          <div class="role role_4" @click.stop="choiceRole('咨询')"></div>
        </div>
      </div>
      <div class="xiaoweiqy" @click.stop="choiceRoleTag('小微企业')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '小微企业' && showRole">
          <div class="role role_1" @click.stop="choiceRole('企业主')"></div>
        </div>
      </div>
      <div class="guoqiyangqi" @click.stop="choiceRoleTag('国企央企')">
        <div class="img" v-show="dialogRoleVisible && roleTag == '国企央企' && showRole">
          <div class="role role_1" @click.stop="choiceRole('董事长')"></div>
          <div class="role role_2" @click.stop="choiceRole('总经理')"></div>
          <div class="role role_3" @click.stop="choiceRole('财务总监')"></div>
        </div>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import userApi from "../../../assets/js/userApi";
export default {
  name: "roleSelector",
  props : {
    title : {
      type    : String,
      default : "选择角色",
    },
    dialogRoleVisible : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
      loading: false,
      showRole : false,
      roleData: [
        {
          'name': '投行',
          'role': ['承揽','承做','内核','合规','承销','高管']
        },
        {
          'name': '评级机构',
          'role': ['分析师','内核','高管']
        },
        {
          'name': '上市公司',
          'role': ['董事长','董秘','证代','财务总监']
        },
        {
          'name': '中大企业',
          'role': ['董事长','总经理','财务总监']
        },
        {
          'name': '财税服务',
          'role': ['代记','审计','税筹','咨询']
        },
        {
          'name': '小微企业',
          'role': ['企业主']
        },
        {
          'name': '国企央企',
          'role': ['董事长','总经理','财务总监']
        }
      ],
      matchTags: [],
      roleTag: ''
    }
  },
  created() {
    this.roleData.forEach((item,index)=>{
      this.matchTags.push('role_' + item.name)
      item.role.forEach((item1,index1)=>{
        this.matchTags.push('role_' + item.name + '_' + item1)
      })
    })
  },
  mounted() {
    document.addEventListener("click", this.roleCloseSelect);
  },
  methods:{
    roleCloseSelect(e) {
      if (this.showRole === true){
        this.showRole = false;
      }
    },
    beforeDestroy() {
      document.removeEventListener("click", this.roleCloseSelect);
    },
    choiceRoleTag(tag) {
      console.log(tag);
      this.roleTag = tag
      this.showRole = true
    },
    choiceRole(role) {
      console.log(this.roleTag+'_'+role)
      this.loading = true
      let _curUserInfo  = JSON.parse( sessionStorage.getItem( "USER_INFO" ) );
      let _tags         = _curUserInfo?.tags;
      let _roleTag      = `role_${this.roleTag}`;
      let _roleTagDetail      = `role_${this.roleTag}_${role}`;
      let _matchTags    = [];
      Object.keys( _tags ).forEach( _t => {
        if( this.matchTags.indexOf( _t ) >= 0 )
          _matchTags.push( _t );
      } )
      userApi.setCurUsrTags( [_roleTag, _roleTagDetail], _matchTags, _r => {
        userApi.getCurUsrInfo().then( _ => {
          this.loading = false
          this.showRole = false
          this.$emit( "tagChanged" );
        } )
      } )
    },
    onClosed(){
      this.$emit( 'close' );
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/index.less";
</style>
