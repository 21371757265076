import apiAxios from "@/config/apiAxios";
import qs from 'qs';
import CryptoJS from "crypto-js";

export default {
    /**
     * 获取微信登录二维码
     * https://dcloud.genesysinfo.net/sso-center/test/wechatPub/ppop/{appCode}/{wxmpCode}/{scenceCode}
     */
    getWxLoginImg( conf ){
        return new Promise( ( resolve, reject) => {
            apiAxios.get(`${config.ssovfsUrl}wechat/qrcode?scene_str=${config.appCode}${config.wxmpCode}${config.scenceCode}`, {}, _r => {
                if (_r && _r?.code == "200") {
                    resolve(_r);
                    // if( _r?.data?.qrcodeUrl ){
                    //     resolve( _r.data );
                    // }else{
                    //     console.warn( "get wechat-img faild1!" );
                    //     reject( _r );
                    // }
                } else {
                    console.warn("get wechat-img faild2!");
                    reject(_r);
                }
            })



            return;
            apiAxios.get( `${config.ssoUrl}/wechatPub/ppop/${conf.appCode}/${conf.wxmpCode}/${conf.scenceCode}`, {}, _r => {
                if( _r && _r?.code == "200" ){
                    if( _r?.data?.qrcodeUrl ){
                        resolve( _r.data );
                    }else{
                        console.warn( "get wechat-img faild1!" );
                        reject( _r );
                    }
                }else{
                    console.warn( "get wechat-img faild2!" );
                    reject( _r );
                }
            } )
        } )
    },
    /**
     * 获取微信登录二维码
     * https://dcloud.genesysinfo.net/sso-center/test/wechatPub/ppop/{appCode}/{wxmpCode}/{scenceCode}
     */
    getWxwLoginImg(){
        return new Promise( ( resolve, reject) => {
            apiAxios.get( `${config.ssoUrl}/wxwPub/ppop/${config.appCode}/${config.wwmpCode}/${config.wwScenceCode}`, {}, _r => {
                if( _r && _r?.code == "200" ){
                    resolve( _r.data );
                }else{
                    console.warn( "get wxwLogin-img faild!" );
                    reject( _r );
                }
            } )
        } )
    },

    /**
     * 获取用户扫码状态
     * @param sceneId
     * @returns {Promise<unknown>}
     */
    checkUserScan( sceneId ){
        return new Promise( ( resolve, reject ) => {
            apiAxios.get(`${config.ssovfsUrl}wechat/check/scan`, {
                "scene_id": sceneId
            }, _r => {
                if (_r && _r?.code == "200") {
                    let data = JSON.parse(_r.data);
                    if (data.scan == 0) {
                        reject(_r)
                    } else if (data.scan == 1) {
                        // let _urlSearch = new URL(data?.redirectUrl).search.slice(1);
                        // let _params = qs.parse(_urlSearch, { allowPrototypes: true });
                        // let _code = _params.code;
                        // let _loginInfo = JSON.parse(CryptoJS.enc.Base64.parse(_code).toString(CryptoJS.enc.Utf8));
                        resolve(data);
                    }
                }

            })

            return;
            apiAxios.get( `${config.ssoUrl}/wechatPub/checkUserScan`, {
                "sceneId" : sceneId
            }, _r => {
                if( _r?.data?.scan == 1 ){
                    let _urlSearch  = new URL( _r?.data?.redirectUrl ).search.slice( 1 );
                    let _params     = qs.parse( _urlSearch, { allowPrototypes: true } );
                    let _code       = _params.code;
                    let _loginInfo  = JSON.parse( CryptoJS.enc.Base64.parse( _code ).toString( CryptoJS.enc.Utf8 ) );
                    resolve( _loginInfo );
                }else{
                    reject( _r );
                }
            } )
        } )
    },

    /**
     * 获取用户扫码状态
     * @param sceneId
     * @returns {Promise<unknown>}
     */
    checkUserWxwScan( sceneId ){
        return new Promise( ( resolve, reject ) => {
            apiAxios.get( `${config.ssoUrl}/wxwPub/checkUserScan`, {
                "sceneId" : sceneId
            }, _r => {
                if( _r?.data?.scan == 1 ){
                    let _urlSearch  = new URL( _r?.data?.redirectUrl ).search.slice( 1 );
                    let _params     = qs.parse( _urlSearch, { allowPrototypes: true } );
                    let _code       = _params.code;
                    let _loginInfo  = JSON.parse( CryptoJS.enc.Base64.parse( _code ).toString( CryptoJS.enc.Utf8 ) );
                    resolve( _loginInfo );
                }else{
                    reject( _r );
                }
            } )
        } )
    },

    /**
     * 新sso登陆
     * @param {} url 
     */
    doNewLogin(url){
        return new Promise((resolve, reject) => {
            apiAxios.get(url, {}, _r => {
                if (_r.code == 200) {

                    let token = _r.token;
                    let satoken = _r.satoken;
                    let userInfo = _r.data;
                    sessionStorage.setItem(
                        "token",
                        token
                    );
                    sessionStorage.setItem("USER_TOKEN_TAG", "satoken");
                    sessionStorage.setItem(
                        "USER_TOKEN_VAL",
                        satoken
                    );
                    localStorage.setItem("USER_TOKEN_TAG", "satoken");
                    localStorage.setItem(
                        "USER_TOKEN_VAL",
                        satoken
                    );
                    sessionStorage.setItem("USER_ISLOGIN", true);
                    localStorage.setItem('USER_ISLOGIN', true);
                    sessionStorage.setItem("USER_INFO", JSON.stringify(userInfo));
                    localStorage.setItem(
                        "token",
                        token
                    );
                  

                    resolve({ code: 200 })


                } else {
                    reject({ code: 500 });
                }
            })
        })
    },

    /**
     * 用户登录操作
     * @param param
     */
    doLogin( param ){
        return new Promise( ( resolve, reject ) => {
            /*获取登录地址*/
            apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/getSsoLogin`, param, _r => {
                if( _r?.code == "200" ){
                    let _loginUrl   = _r.data?.url;
                    if( !_loginUrl || _loginUrl == undefined ){
                        this.$message.error( "获取登录地址失败" );
                    }else{
                        /*发送登录请求*/
                        if( _loginUrl == `${config.serverUrl}/sso-center/sso` )
                            _loginUrl   = "https://${config.serverUrl}/sso-center/sso";
                        apiAxios.postRequestForm( `${_loginUrl}/doLogin`, param, (_tokenInfo,_r1) => {
                            if( _r1?.data?.code == "200" ){
                                apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/getSsoLoginTicket`, {token:_tokenInfo.data?.tokenValue,url:`${window.localUrl}/redir.html?a=1`}, _r => {
                                    let _tickitUrl  = _r?.data?.pop();
                                    if( _tickitUrl && _tickitUrl != undefined ){
                                        let _param  = qs.parse( _tickitUrl );
                                        apiAxios.postRequestForm( `${config.serverUrl}/cmss/v1/comp-service/doLoginByTicket`, {ticket:_param.ticket}, _r => {
                                            sessionStorage.setItem( 'USER_TOKEN_TAG', _r.data?.tokenName );
                                            sessionStorage.setItem( 'USER_TOKEN_VAL', _r.data?.tokenValue );
                                            sessionStorage.setItem( 'USER_ISLOGIN', true );

                                            localStorage.setItem( 'USER_TOKEN_TAG', _r.data?.tokenName );
                                            localStorage.setItem( 'USER_TOKEN_VAL', _r.data?.tokenValue );
                                            resolve( _r.data );
                                        } );
                                    }else{
                                        reject( _r );
                                        console.error( "get login ticket faild" );
                                    }
                                } );
                            }else{
                                reject( _r1 );
                            }
                        } );
                    }
                }else{
                    this.$message.error( "登录失败" );
                }
            } );
        } )
    },

    doLogout( response ){
        sessionStorage.setItem( 'USER_ISLOGIN', false );
        localStorage.setItem( 'USER_ISLOGIN', false );
        response();
    },

    /**
     * 获取当前登录用户信息
     */
    getCurUsrInfo( appId ){
        return new Promise( ( resolve, reject ) => {
            apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/user/center/info`, {}, _r => {
                if( _r?.code == "200" ){
                    let _uInfo   = _r?.data;

                    let _conf       = JSON.parse( localStorage.getItem( "USER_INFO" ) );
                    let _isFix      = true;

                    Object.keys( _conf ).forEach( _k => {
                        if( _conf[_k]?.required && ( !_uInfo[_k] || _uInfo[_k].length <= 0 ) ){
                            _isFix  = false;
                        }
                    } );

                    if( appId ){
                        let _confApp    = JSON.parse( localStorage.getItem( "USER_INFO_CHECK" ) );
                        Object.keys( _confApp ).forEach( _k => {
                            if( _confApp[_k]?.required && ( !_uInfo[_k] || _uInfo[_k].length <= 0 ) ){
                                _isFix  = false;
                            }
                        } );
                    }

                    sessionStorage.setItem( 'USER_ISLOGIN', true );
                    sessionStorage.setItem( 'USER_ID', _uInfo?.id );
                    sessionStorage.setItem( 'USER_INFO', JSON.stringify( _uInfo ) );
                    sessionStorage.setItem( "USER_INFO_FIXED", _isFix );

                    localStorage.setItem( "USER_INFO_CONF", JSON.stringify( _conf ) );
                    this.getCurUserCompanyInfo( _r => {
                        if( _r ){
                            sessionStorage.setItem( 'USER_COMPANY', JSON.stringify( _r ) );
                        }
                        resolve( _uInfo );
                    } )
                }else{
                    reject( _r );
                }
            } )
        })
    },

    /**
     * 获取当前用户的企业信息
     * @param response
     */
    getCurUserCompanyInfo( response ){
        let _userInfo   = JSON.parse( sessionStorage.getItem( "USER_INFO" ) );
        apiAxios.agentGet( `${config.serverUrl}/cmbs/v1/jump/thdb/lexicon/organization?filter=('seccode' eq '${_userInfo.seccode}')&limit=1`, {}, _r => {
            if( _r?.data?.length > 0 ){
                response( _r?.data[0] );
            }else
                response( false );
        })
    },

    /**
     * 获取当前用户企业代码对应的EID信息
     */
    getCurUsrSecEid( response ){
        let _userInfo   = JSON.parse( sessionStorage.getItem( "USER_INFO" ) );
        apiAxios.agentGet( `${config.serverUrl}/cmbs/v1/jump/thdb/lexicon/organizationFull/first?filter=( 'word' eq '${_userInfo.seccode}' )`, {}, _r => {
            response( _r.data?.pos?.split( "+" )?.shift() );
        } )
    },

    /**
     * 获取当前用户标签
     * @param response
     */
    getCurUsrTags( response ){
        apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/user/center/tag`, {}, response );
    },

    /**
     * 修改当前用户标签
     * @param addTags:要添加的标签
     * @param delTags：要删除的标签
     * @param response
     */
    setCurUsrTags( addTags, delTags, response ){
        let _tagOps = [];
        if( addTags?.length > 0 ){
            addTags.map( _tag => _tagOps.push( {"op":"add","path":`/${_tag}`,"value":true}) );
        }
        if( delTags?.length > 0 ){
            delTags.map( _tag => _tagOps.push( {"op":"remove","path":`/${_tag}`,"value":true}) );
        }
        apiAxios.post( `${config.serverUrl}/cmss/v1/comp-service/user/center/tag`, _tagOps, response );
    },

    /**
     * 修改用户信息
     * @param param
     * @param response
     * @returns {Promise<unknown>}
     */
    saveUserInfo( userInfo, response ){
        apiAxios.post( `${config.serverUrl}/cmss/v1/comp-service/user/center/saveUserInfo`, userInfo, response );
    },

    /**
     * 修改用户信息并提交审核
     * @param param
     * @param response
     * @returns {Promise<unknown>}
     */
    saveUserInfoCheck( userInfo, response, actInfo ){
        apiAxios.post( `${config.serverUrl}/cmss/v1/comp-service/user/center/saveUserInfo?action=doWithMsg`, userInfo, response, actInfo );
    },

    getUserWorkLog( params, page, limit, response ){
        let _filter = `matchesPattern('message.app_name','.*.*') AND matchesPattern('message.app_id','^\\d.*$') AND ( object_uuid eq '${sessionStorage.getItem( "USER_ID" )}' )`;
        Object.keys( params ).forEach( _k => {
            _filter += ` AND ( '${_k}' eq '${params[_k]}' )`
        } )
        let _params = {
            url     : `${config.serverUrl}/cmbs/v1/jump/thdb/cmdp/user_behavior/pagination?filter=${_filter}&skip=${( page - 1 ) * limit}&limit=${limit}`,
            method  : "GET",
            body    : {},
            header  : {},
            files   : [],
        }
        apiAxios.post(  `${config.serverUrl}/cmss/v1/comp-service/api/send`, _params, _r => {
            response( _r )
        } )


        /*let _filter = `matchesPattern('message.app_name','.*.*') AND ( contains( summary, 'STATUS' ) )`;
        apiAxios.post( `${config.serverUrl}/cmss/v1/comp-service/logs/list`, {
            // filter  : "contains( summary, 'STATUS' )",
            filter  : _filter,
            skip    : ( page - 1 ) * limit,
            limit   : limit
        }, _r => {
            response( _r );
        } )*/
    },

    getUserWorkLogByApp( appId, params, page, limit, response ){
        if( appId )
            params["message.app_id"]    = appId;
        params["deal_stage"]        = "STATUS";
        this.getUserWorkLog( params, page, limit, response );
    },

    getUserMessage( params, response ){
        apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/letter`, params, response );
    },

    /**
     * 修改用户个人配置
     * @param param
     * @param response
     */
    setUserAppSet( param, response ){
        apiAxios.post( `${config.ssoUrl}/app/user/${config.appCode}`, param, _r => {
            response( _r )
        } )
    },

    /**
     * 获取用户个人配置
     * @param response
     */
    getUserAppSet( response ){
        let _curUserId  = sessionStorage.getItem( "USER_ID" );
        apiAxios.get( `${config.ssoUrl}/app/user/${config.appCode}`, {
            userId : _curUserId
        }, _r => {
            if( !_r.data )
                _r.data = {
                    "userId": sessionStorage.getItem( "USER_ID" ),
                    "favs"  : false,
                    "apps"  : [],
                    "homePage" : "/app/detail/0000"
            };
            response( _r )
        } )
    },

    /**
     * 发送意见反馈
     * @param context
     */
    sendFeedBack( context, response ){
        apiAxios.post( `${config.serverUrl}/cmss/v1/comp-service/user/feedback`, {
            feedbackContent : context
        }, response );
    },

    /**
     * 根据用户ID列表查询用户信息
     */
    getUsersByIds( userIds, response ){
        apiAxios.agentPost( `${config.ssoUrl}/user/list`, { uids : userIds }, response );
    },

    /**
     * 游客填写公司信息，更新
     * @param {*} userInfo
     * @param {*} response
     */
    customerSaveUserInfo(userInfo, response){
        apiAxios.post(`${config.ssoUrl}/user`, userInfo, response );
    },
	
	
	
	/**
	 * 登录(账号密码登录)
	 * @param {Object} param
	 */
	doLoginByUP(param) {
		
		return new Promise((resolve, reject) => {
			
			/*获取登录地址*/
			apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/getSsoLogin`, param, _r => {
				console.log(_r)
				
				if(_r?.code != 200) reject("登录失败");
				
				let _loginUrl   = _r.data?.url;
				if( !_loginUrl || _loginUrl == undefined ) reject("获取登录地址失败");
				
				console.log(_loginUrl)
				/*发送登录请求*/
				apiAxios.postRequestForm( `${_loginUrl}/doLogin`, param, (_r) => {
					if(_r?.code != 200) reject(_r.msg || '登录错误');
					console.log(_r)
					resolve(_r?.data)
				} );
			} );
		})
		
	}
	
	
}
