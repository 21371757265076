<template>
  <div class="wechat-login" :class="{disabled:reloadTimeout}" v-loading="!wechatImg" @click="getLoginImg">
    <div class="flex-row-center">
      <img v-if="wechatImg" :src="wechatImg" />
      <img v-else :src="`/img/${viewSkin}/common/unImg.png`" />
<!--      <img class="reload" src="../../assets/img/def/common/qrcode-fresh.png">-->
    </div>
  </div>
</template>

<script>
import userApi from "@/assets/js/userApi"
import appApi from "@/assets/js/appApi"
export default {
  name: "wechat-login",
  props : {
    visabled  : false
  },
  data(){
    return {
      sceneId       : false,    //每次生成的二维码唯一编码，用于获取扫码状态
      wechatImg     : false,    //二维码URL
      expire        : -1,
      loginTimeout  : false,
      reloadTimeout : false,
    }
  },
  watch   : {
    visabled( newValue ){
    }
  },
  methods : {
    /**
     * 获取当前用户信息
     */
    getCurUserInfo( _apiId ){
      return userApi.getCurUsrInfo( _apiId );
    },

    /**
     * 获取当前用户APP配置
     */
    getUserAppSet(){
      return new Promise( (resolve, reject) => {
        userApi.getUserAppSet( _r => {
          resolve( _r );
        } )
      } )
    },

    /**
     * 获取用户快捷应用配置
     */
    getUserFav( userAppSet ){
      return new Promise( ( resolve, reject ) => {
        if( !userAppSet?.favs ){
          //首次登录，添加推荐应用快捷方式
          appApi.getFreeAppList(  5, "_id,title",_r => {
            userAppSet.favs = [];
            _r?.data?.forEach( _a => {
              userAppSet.favs.push({
                id  : _a._id,
                name: _a.title
              })
            } )
            sessionStorage.setItem( "USER_APP_FAV", JSON.stringify( userAppSet?.favs ) );
            this.$store.dispatch( "setFavAppList",  userAppSet?.favs );
            this.saveUserSetting( userAppSet );
            resolve();
          } )
        }else{
          sessionStorage.setItem( "USER_APP_FAV", JSON.stringify( userAppSet?.favs ) );
          this.$store.dispatch( "setFavAppList",  userAppSet?.favs );
          resolve();
        }
      } )
    },

    /**
     * 保存用户配置信息
     */
    saveUserSetting( userAppSet ){
      userApi.setUserAppSet( userAppSet );
    },

    /**
     * 获取登录二维码
     */
    getLoginImg(){
      if( this.loginTimeout )
        window.clearTimeout( this.loginTimeout );
      this.wechatImg      = false;
      this.reloadTimeout  = false;

      userApi.getWxLoginImg( this.SYS_CONF[this.sysTag]).then( data => {
      //  this.wechatImg  = data.qrcodeUrl;
      this.wechatImg="data:image/jpg;base64,"+data.qrcodeUrl;
        this.sceneId    = data.sceneId;
        this.expire     = Math.floor( new Date().getTime() / 1000 ) + ( data?.expireSeconds || 120 );
        this.getLoginStatus();
        window.setTimeout( () => {
          window.clearTimeout( this.loginTimeout );
          this.loginTimeout   = false;
          this.reloadTimeout  = true;
        }, 60000 )
      } )
    },

    /**
     * 获取扫码结果
     * https://dcloud.genesysinfo.net/sso-center/test/wechatPub/checkUserScan?sceneId=
     */
    getLoginStatus(){
      if( this.expire <= Math.floor( new Date().getTime() / 1000 )  ){
        this.getLoginImg();
      }else{
        userApi.checkUserScan( this.sceneId ).then( _loginInfo => {
          userApi.doLogin( _loginInfo ).then( _data => {
            let _redir  = "";
            this.onLoginSuccess( _data, _redir );
          } ).catch( _r => {
            this.$notify( { type: 'warning', message: '登录失败' } );
          } )
        } ).catch( () => {
          this.loginTimeout = window.setTimeout( ()=>{
            this.getLoginStatus( this.sceneId )
          }, 3000 );
        } )
      }
    },


    /**
     * 登录成功
     */
    onLoginSuccess(){
      let _apiId      = this.$route.query?.a || false;
      this.getCurUserInfo( _apiId ).then( userInfo => {
        this.$emit( "login-success" );
      } ).catch( _r => {
        this.getLoginImg();
        sessionStorage.setItem( 'USER_ISLOGIN', false );
        this.$store.dispatch( "setLogin", false );
        this.$notify( { type: 'warning', message: '登录失败' } );
      });
    }
  },
  mounted() {
    if( this.loginTimeout ){
      window.clearTimeout( this.loginTimeout );
      this.loginTimeout = false;
    }
    if( this.reloadTimeout ){
      window.clearTimeout( this.reloadTimeout );
      this.reloadTimeout = false;
    }

    let _tokenTag = localStorage.getItem( "USER_TOKEN_TAG" );
    let _tokenVal = localStorage.getItem( "USER_TOKEN_VAL" );
    if( _tokenTag && _tokenVal ){
      sessionStorage.setItem( 'USER_TOKEN_TAG', _tokenTag );
      sessionStorage.setItem( 'USER_TOKEN_VAL', _tokenVal );
      sessionStorage.setItem( 'USER_ISLOGIN', true );
      this.onLoginSuccess();
    }else
      this.getLoginImg();
  },
  beforeDestroy() {
    if( this.loginTimeout ){
      window.clearTimeout( this.loginTimeout );
      this.loginTimeout = false;
    }
    if( this.reloadTimeout ){
      window.clearTimeout( this.reloadTimeout );
      this.reloadTimeout = false;
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/define.less";
.wechat-login{
  width:100%;
  text-align: center;
  position: relative;
  &.disabled{
    &:before{
      cursor: pointer;
      position: absolute;
      content : "二维码失效，点击刷新";
      width:100%;
      height:100%;
      background:url( '~@/assets/img/def/common/qrcode-fresh.png' ) no-repeat center;
      background-color:#bg-color[mask];
      font-size:#font-size[big];
      color:#000000;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items:center;
      padding-top:100px;
    }
  }
  img{
    width:360px;
    height:360px;
  }
}
</style>
