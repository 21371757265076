<template>
  <div class="footer">
    <div class="footer-container">
<!--      <div class="img">
        <img :src="require('../assets/img/footer_logo.png')">
      </div>
      <div class="links">
        <el-link :underline="false">智囊工厂</el-link>
        <el-link :underline="false">产品展示</el-link>
        <el-link :underline="false">关于我们</el-link>
      </div>-->
      <div class="bq">
        <p> 深圳市致远速联信息技术有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备13055653号-5</a> </p>
        <p>办公地址：深圳市罗湖区笋岗街道笋西社区宝安北路1007号笋岗七号仓四层401-02单元 &nbsp;&nbsp; 联系电话：0755-25575809</p>
      </div>
 
    </div>
  </div>
</template>

<script>
export default {
  name: "webFooter"
}
</script>

<style lang="less" scoped>
@import "../assets/index.less";
</style>
